// extracted by mini-css-extract-plugin
export var btn = "banner-module--btn--8dtBN";
export var btnBlock = "banner-module--btnBlock--nQD1T";
export var container = "banner-module--container--o4jKt";
export var darkColor = "banner-module--darkColor--lKOXz";
export var hideMobBlock = "banner-module--hideMobBlock--9wvDh";
export var img = "banner-module--img--7s+f8";
export var mobBtn = "banner-module--mobBtn--8SSVo";
export var red = "banner-module--red--hO4lk";
export var secondContainer = "banner-module--secondContainer--StnZb";
export var subTextFriday = "banner-module--subTextFriday--lv0Qu";
export var text = "banner-module--text--9ffM7";